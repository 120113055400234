import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { ApolloLink, Observable } from 'apollo-link';

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      rooms: (_: any, args: any, cache: any) =>
        cache.getCacheKey({ __typename: 'Rooms', id: args?.id || '' }),
      performers: (_: any, args: any, cache: any) =>
        cache.getCacheKey({ __typename: 'Performers', id: args?.id || '' }),
      groups: (_: any, args: any, cache: any) =>
        cache.getCacheKey({ __typename: 'Groups', id: args?.id || '' }),
    },
  },
});

const request = async (operation: any) => {
  const token = await localStorage.getItem('token');
  operation.setContext({
    headers: {
      // authorization: token ? `Bearer ${token}` : '',
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer: any) => {
      let handle: any;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          return (handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }));
        })
        .catch(observer.error.bind(observer));
      return () => {
        handle.unsubscribe();
      };
    })
);

const handleError = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors[0]);
  }
});

// const graphqlEndpoint =  new HttpLink({
//   uri: `${process.env.REACT_APP_ENDPOINT}/graphql`,
//   credentials: 'same-origin'
// });

const graphqlEndpoint = new HttpLink({
  uri: `${process.env.REACT_APP_ENDPOINT}/graphql`,
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    handleError,
    requestLink,
    withClientState({
      defaults: {
        isConnected: true,
      },
      resolvers: {
        Mutation: {
          updateNetworkStatus: (_: any, connected: any, cacheObject: any) => {
            cacheObject?.cache.writeData({
              data: { isConnected: connected.isConnected },
            });
            return null;
          },
        },
      },
      cache,
    }),
    graphqlEndpoint,
  ]),
  cache,
});
